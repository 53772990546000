<template>
  <div>
    <!-- 卡片视图区域 -->
    <el-card>
      <el-row>
        <el-col>
          <el-button type="primary" size="small" @click="showDialog">添加角色</el-button>
        </el-col>
      </el-row>
      <!-- table表格区域 -->
      <el-table :data="rolePage" border stripe style="margin:10px" v-loading="loading">
        <el-table-column align="center" label="id" prop="id" width="180px"></el-table-column>
        <el-table-column align="center" label="角色名称" prop="roleName"></el-table-column>
        <el-table-column align="center" label="角色状态" prop="status">
          <template slot-scope="scope">
            <el-tag :type="scope.row.status === 0 ? 'success' : 'danger'" disable-transitions>
              {{ scope.row.status === 0 ? '启用' : '停用' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="260px">
          <template slot-scope="scope">
            <el-button type="primary" icon="el-icon-edit" size="mini" @click="setRoles(scope.row)">编辑</el-button>
            <el-button type="danger" icon="el-icon-delete" size="mini" @click="delRoles(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                     :current-page="querInfo.current" :page-sizes="[5, 10, 15]" :page-size="querInfo.size"
                     layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
    </el-card>
    <!-- 添加角色对话框 -->
    <el-dialog title="添加角色" :visible.sync="addRoleDialog" width="30%" :before-close="resetChecked">
      <!-- 内容主题区域 -->
      <el-form :model="addRole" ref="addRoleRef" :rules="rules">
        <el-form-item label="角色名称：" prop="roleName" label-width="100px">
          <el-input v-model="addRole.roleName"></el-input>
        </el-form-item>
        <el-form-item label="角色权限：" prop="menuId" label-width="100px">
          <el-tree ref="tree" :data="roleMenu" default-expand-all show-checkbox node-key="id"
                   :props="roleMenu"></el-tree>
        </el-form-item>

        <el-form-item label="状态：" label-width="100px">
          <el-switch v-model="addStatu" active-text="启用" inactive-text="停用"></el-switch>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetChecked">取 消</el-button>
        <el-button type="primary" @click="getCheckedKeys">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑角色对话框 -->
    <el-dialog title="编辑角色" :visible.sync="setRoleDialog" width="30%" :before-close="closesetRole">
      <!-- 内容主题区域 -->
      <el-form :model="setRole" ref="setRoleRef">
        <el-form-item label="角色名称：" prop="roleName" label-width="100px">
          <el-input v-model="setRole.roleName"></el-input>
        </el-form-item>
        <el-form-item label="角色权限：" prop="menuId" label-width="100px">
          <el-tree ref="trees" :data="roleMenu" show-checkbox node-key="id" :props="roleMenu"
                   :default-checked-keys="setRoleMenuId" default-expand-all :default-expanded-keys="setRoleMenuId"
                   :check-strictly="true"></el-tree>
        </el-form-item>
        <el-form-item label="状态：" label-width="100px">
          <el-switch v-model="updataStatu" active-text="启用" inactive-text="停用"></el-switch>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="closesetRole">取 消</el-button>
        <el-button type="primary" @click="addSetRole">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {GetRolePage, GetRoleMenu, AddRole, SetRole, DelRole} from '@/apis/system.js'

export default {
  data() {
    return {
      querInfo: {
        current: 1,
        size: 10
      },
      // 角色列表
      rolePage: [],
      // 角色菜单集合
      roleMenu: [],
      // 总数据条数
      total: 0,
      // 添加角色
      addRole: {
        roleName: '',
        status: '',
        menuId: ''
      },
      rules: {
        roleName: [
          {required: true, message: '请输入角色名称', trigger: 'blur'},

        ],
      },
      roleMenuId: [],
      // 控制添加对话框的开启
      addRoleDialog: false,
      addStatu: true,
      // 控制编辑对话框的开启
      setRoleDialog: false,
      // 编辑角色
      setRole: {
        id: '',
        roleName: '',
        status: '',
        menuId: ''
      },
      // 编辑菜单状态
      updataStatu: true,
      setRoleMenuId: [],
      loading: false
    }
  },
  created() {
    this.getRolePage()
  },
  methods: {
    getRolePage() {
      this.loading = true
      GetRolePage(this.querInfo).then(data => {
        this.rolePage = data.data.records
        this.querInfo.current = data.data.current
        this.querInfo.size = data.data.size
        this.total = data.data.total
      })
      setTimeout(() => {
        this.loading = false
      }, 50)
      GetRoleMenu().then(params => {
        this.roleMenu = params.data
      })
    },
    // 监听 pagesize 改变
    handleSizeChange(newSize) {
      this.querInfo.size = newSize
      this.getRolePage()
    },
    // 监听 pagenum 改变
    handleCurrentChange(newPage) {
      this.querInfo.current = newPage
      this.getRolePage()
    },
    // 添加对话框的开启
    showDialog() {
      this.addRoleDialog = true
    },
    // 添加角色
    getCheckedKeys() {
      this.roleMenuId = this.$refs.tree.getCheckedKeys().concat(this.$refs.tree.getHalfCheckedKeys())
      this.$refs.addRoleRef.validate(valid => {
        if (valid) {
          if (this.addStatu) {
            this.addRole.status = 0
          } else {
            this.addRole.status = 1
          }
          this.addRole.menuId = this.roleMenuId.join(',')
          AddRole(this.addRole).then(data => {
            this.roleMenuId = []
            this.closeRole()
            this.getRolePage()
          })
        }
      })
    },
    // 添加对话框清空
    closeRole() {
      this.addRoleDialog = false
      this.addStatu = true
      this.$refs.tree.setCheckedKeys([])
      this.$refs.addRoleRef.resetFields()
    },
    // 添加角色对话框的关闭事件
    resetChecked() {
      this.closeRole()
    },
    // 编辑对话框的开启
    setRoles(scope) {
      this.setRoleDialog = true
      let munid = (scope.menuId || '').split(',')
      this.setRoleMenuId = munid.map(function (data) {
        return +data
      })
      this.setRole.roleName = scope.roleName
      this.setRole.id = scope.id
      if (scope.status === 0) {
        this.updataStatu = true
      } else {
        this.updataStatu = false
      }
    },
    // 提交编辑
    addSetRole() {
      this.setRoleMenuId = this.$refs.trees.getCheckedKeys().concat(this.$refs.trees.getHalfCheckedKeys())
      this.$refs.setRoleRef.validate(valid => {
        if (valid) {
          if (this.updataStatu) {
            this.setRole.status = 0
          } else {
            this.setRole.status = 1
          }
          this.setRole.menuId = this.setRoleMenuId.join(',')
          SetRole(this.setRole).then(data => {
            this.$message.success(data.msg);
            this.roleMenuId = []
            this.closesetRole()
          })
        } else {
          this.$message.error('请输入正确的表单格式')
        }
      })
    },
    // 编辑对话框关闭事件
    closesetRole() {
      this.setRoleDialog = false
      this.$refs.trees.setCheckedKeys([])
      this.getRolePage()
    },
    // 删除角色
    async delRoles(id) {
      const confirmResult = await this.$confirm('此操作将永久删除该角色, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('取消了删除！')
      }
      let data = {id: id}
      DelRole(data).then(params => {
        this.$message({
          type: 'success',
          message: params.msg
        })
        this.getRolePage()
      })
    }
  }
}
</script>
<style lang="less" scoped></style>
